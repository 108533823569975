<template>
  <div class="tile">
    <div class="tile is-parent">
      <article class="tile is-child detail-page-tile">
        <div class="columns title">
          <div class="column">
            <div class="subtitle">
              <div class="p-1">
                <div class="is-flex columns">
                  <div class="column">
                    <div>
                      <span class="category-name">{{ category }}</span>
                      <span v-if="isPrivate"
                        class="icon pl-3">
                        <i class="mdi mdi-eye-off mdi-24px" />
                      </span>
                      <span v-if="imagesWithInfo.length > 0"
                        class="has-badge-rounded has-badge-info has-badge-large pl-3"
                        :data-badge="imagesWithInfo.length" />
                    </div>
                  </div>
                  <div class="is-flex column is-narrow is-justify-content-flex-end">
                    <p class="buttons">
                      <!-- <a class="button tooltip"
                @click="getThumbnails"
                data-tooltip="Refresh"
                :disabled="isLoadingImages">
                <span class="icon">
                  <i class="mdi mdi-refresh mdi-24px" />
                </span>
              </a> -->
                      <a class="button tooltip"
                        v-if="imagesWithInfo.length > 0"
                        @click="setSelectedStatusAll(true)"
                        data-tooltip="Select all"
                        :disabled="isLoadingImages">
                        <span class="icon has-text-success">
                          <i class="mdi mdi-select-all mdi-24px" />
                        </span>
                      </a>
                      <a v-show="hasSelected"
                        class="button tooltip"
                        @click="setSelectedStatusAll(false)"
                        data-tooltip="Deselect all">
                        <span class="icon has-text-grey">
                          <i class="mdi mdi-select-off mdi-24px" />
                        </span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div>
          <draggable :list="imagesWithInfo"
            group="imagesWithInfo"
            class="is-flex columns is-multiline dragable-box"
            @change="(event)=>onImageOrderChange(categoryId,event)"
            handle=".item-drag-handle">
            <div v-for="(image, index) in imagesWithInfo"
              :key="image.quoteImageId"
              class="is-flex column is-narrow p-2 item-drag">
              <div v-if="image.imageContent"
                :class="'card'"
                @dblclick="makeImagePrimary(image)">
                <header class="card-header-line">
                  <div class="card-header-title p-2 has-background-light">
                    <div class="is-flex columns is-gapless"
                      style="width: 100%">
                      <div class="is-flex column">
                        <div class="field">
                          <div class="control">
                            <div class="pretty p-icon p-curve p-smooth">
                              <input :id="`chk-pretty-image1${index}`"
                                :name="`chk-pretty-image${index}`"
                                v-model="image.isSelected"
                                type="checkbox"
                                @change="selectImage(image.quoteImageId,image.isSelected)">
                              <div class="state p-success">
                                <i class="icon mdi mdi-check" />
                                <label />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="is-flex column is-narrow">
                        <a class="has-text-primary is-outlined"
                          @click="editImage(image.quoteImageId)">
                          <span class="icon">
                            <i class="mdi mdi-lead-pencil mdi-24px"
                              aria-hidden="true" />
                          </span>
                        </a>
                        <a class="has-text-danger is-outlined"
                          @click="showDeleteModal(image.quoteImageId)">
                          <span class="icon">
                            <i class="mdi mdi-delete mdi-24px"
                              aria-hidden="true" />
                          </span>
                        </a>
                        <a class="has-text-dark is-outlined item-drag-handle">
                          <span class="icon">
                            <i class="mdi mdi-drag-vertical mdi-24px"
                              aria-hidden="true" />
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </header>
                <div class="card-image">
                  <a @click="viewImage(index)">
                    <figure class="image is-128x128">
                      <div style="position: relative">
                        <i v-if="image.isPrimary"
                          class="mdi mdi-star mdi-24px has-text-gold"
                          style="position: absolute; z-index: 1" />
                        <img v-if="image.imageContent"
                          class="image is-128x128"
                          style="object-fit: cover;"
                          :src="image.imageContent"
                          :key="image.quoteImageId"
                          :id="image.quoteImageId">
                      </div>
                    </figure>
                  </a>
                </div>
              </div>
            </div>

          </draggable>
        </div>
      </article>
    </div>
    <confirm-modal :active.sync="isConfirmModalActive"
      @ok="deleteImage(true)"
      @cancel="deleteImage(false)"
      :ok-text="'Yes'"
      :cancel-text="'No'">
      <p slot="text-title">Delete image</p>
      <p slot="text-content">
        Image will be deleted. Continue?
      </p>
    </confirm-modal>
    <bulma-carousel-modal v-if="isCarouselModalActive"
      :image-width="800"
      :image-height="600"
      :active.sync="isCarouselModalActive"
      :image-ids="imageIds"
      :start-index="carouselStartIndex"
      @close="closeCarousel" />
  </div>
</template>

<script>
import QuoteService from '@/views/quote/QuoteService'
import { OpenImage } from '@/components/VuePainterro'
import { ConfirmModal } from '@/components/BulmaModal'
import { BulmaCarouselModal } from '@/components/BulmaCarousel'
import draggable from 'vuedraggable'
// import mimeTypes from 'mime-types'
import { EventHubTypes } from '@/enums'
// import CommonApiService from '@/services/common'
import _cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'QuoteImageCategory',
  components: {
    ConfirmModal,
    BulmaCarouselModal,
    draggable
  },
  props: {
    quoteId: {
      type: String
    },
    category: {
      type: String
    },
    categories: {
      type: Array,
      default: () => []
    },
    images: {
      type: Array,
      default: () => []
    },
    categoryId: {
      type: String
    },
    value: null,
    hasSelect: {
      type: Boolean,
      default: false
    },
    refresh: {
      type: Boolean,
      default: false
    },
    categoryOrderIndex: {
      type: Number
    },
    isPrivate: {
      type: Boolean
    }
  },
  data() {
    return {
      size: 200,
      innerImages: null,
      imagesWithInfo: [],
      selectAll: false,
      imageIds: null,
      isConfirmModalActive: false,
      selectedImageId: '',
      queuedFileCount: 0,
      isDropzoneActive: false,
      isClearAllActive: false,
      isUploading: false,
      isCarouselModalActive: false,
      carouselStartIndex: 0,
      isLoadingImages: false,
      lastScrollX: 0,
      lastScrollY: 0,
      areThumbnailsLoaded: false,
      id: null,
      dragImageId: null,
      dragCategoryId: null
    }
  },
  computed: {
    hasSelected() {
      return this.imagesWithInfo.some((i) => i.isSelected)
    },
    selectedCount() {
      return this.imagesWithInfo.reduce(function (total, item) {
        return item.isSelected ? total + 1 : total
      }, 0)
    }
  },
  watch: {
    hasSelect(value) {
      this.selectAll = value
      this.setSelectedStatusAll(value)
    },
    refresh(value) {
      this.getThumbnails()
    },
    imagesWithInfo(value) {
      const imageIds = this.imagesWithInfo.map((i) => i.quoteImageId)
      this.imageIds = imageIds
    }
  },
  created() {
    this.selectAll = _cloneDeep(this.hasSelect)
    // this.dropzoneOptions.headers.Authorization = `Bearer ${CommonApiService.getAuthToken()}`
    this.getThumbnails()
  },
  mounted() {
    this.id = this._uid + ''
  },
  methods: {
    async getThumbnails() {
      this.isLoadingImages = true
      this.imagesWithInfo.splice(0, this.imagesWithInfo.length)
      // const vm = this
      //this.imageIds = await QuoteService.getImageIdsWithCategory(this.quoteId, this.categoryId)
      if (this.images) {
        const images = this.images.filter((i) => i.imageCategoryId == this.categoryId).sort((a, b) => a.orderIndex - b.orderIndex)
        const noCategoryImages = this.categoryOrderIndex === 0 ? this.images.filter((i) => !i.imageCategoryId) : []
        const combinedImages = images.concat(noCategoryImages).sort((a, b) => a.orderIndex - b.orderIndex)
        // combinedImages.forEach((img, index) => {
        //   img.orderIndex = index
        // })
        this.imagesWithInfo = combinedImages
      }
      // const promises = this.imageIds.map(function (id, index) {
      //   return vm.getThumbnailWithInfo(id, index)
      // })

      // this.imagesWithInfo = await Promise.all(promises)
      this.isLoadingImages = false
      this.areThumbnailsLoaded = true
    },
    async getThumbnailWithInfo(id, index) {
      var thumbnail = await QuoteService.getImageThumbnailWithInfo(id, this.size)
      return thumbnail
    },
    makeImagePrimary(targetImage) {
      for (var image of this.imagesWithInfo) {
        image.isPrimary = image.quoteImageId === targetImage.quoteImageId
      }

      for (var image of this.images) {
        image.isPrimary = image.quoteImageId === targetImage.quoteImageId
      }

      targetImage.isPrimary = true
      QuoteService.setQuoteImageIsPrimary(targetImage.quoteImageId)
    },
    setSelectedStatusAll(selected) {
      this.imagesWithInfo.forEach((i) => (i.isSelected = selected))
      this.imagesWithInfo.forEach((element) => {
        const index = this.images.findIndex((i) => i.quoteImageId === element.quoteImageId)
        if (index !== -1) {
          this.images[index].isSelected = selected
        }
      })
    },
    selectImage(imageid, selected) {
      const index = this.images.findIndex((i) => i.quoteImageId === imageid)
      if (index !== -1) {
        this.images[index].isSelected = selected
      }
    },
    async onImageOrderChange(targetCategory, event) {
      if (event.moved !== undefined) {
        console.log(event.moved)
        //await this.setQuoteImagesOrderIndex()
        this.$emit('arrange-images-order')
      }

      if (event.added !== undefined) {
        var movedItem = event.added.element
        console.log(event.added)
        await QuoteService.setImageCategory(this.value.quoteId, movedItem.quoteImageId, targetCategory)
        //await this.setQuoteImagesOrderIndex()
        this.$emit('arrange-images-order')
        //this.$emit('update-image')
      }
    },
    // async setQuoteImagesOrderIndex() {
    //   const imageIds = this.imagesWithInfo.map((i) => i.quoteImageId)
    //   await QuoteService.setQuoteImagesOrderIndex(this.value.quoteId, imageIds)
    //   //this.imageIds = imageIds
    // },
    async saved(id) {
      // Callback function to update thumbnail after saving images in Painterro
      const thumbnail = this.imagesWithInfo.find((i) => i.quoteImageId === id)
      const updatedThumbnail = await this.getThumbnailWithInfo(id, this.size)
      thumbnail.imageContent = updatedThumbnail.imageContent
      // Force image source update
      // document.getElementById(thumbnail.quoteImageId).src = thumbnail.imageContent
      this.$eventHub.$emit(`${EventHubTypes.ImageCountChanged}UpdateQuote`, 0)
    },
    closed() {
      window.scroll(this.lastScrollX, this.lastScrollY)
    },
    editImage(id) {
      this.$showSpinner()
      this.lastScrollX = window.scrollX
      this.lastScrollY = window.scrollY
      window.scrollTo(0, 0)
      OpenImage(id, this.saved, this.closed, false)
      this.$hideSpinner()
    },
    async viewImage(index) {
      this.$showSpinner()
      this.carouselStartIndex = index
      this.isCarouselModalActive = true
      this.$hideSpinner()
    },
    closeCarousel() {
      this.isCarouselModalActive = false
    },
    showDeleteModal(id) {
      this.selectedImageId = id
      this.isConfirmModalActive = true
    },
    async deleteImage(confirm) {
      this.isConfirmModalActive = false
      if (confirm) {
        await QuoteService.deleteImage(this.selectedImageId)
        const index = this.imageIds.indexOf(this.selectedImageId)
        console.log(index)
        this.imageIds.splice(index, 1)
        const indexToRemove = this.imagesWithInfo.findIndex((i) => i.quoteImageId === this.selectedImageId)
        this.imagesWithInfo.splice(indexToRemove, 1)
        this.$notification.openNotificationWithType('success', 'Delete images', 'image deleted')
        this.$eventHub.$emit(`${EventHubTypes.ImageCountChanged}UpdateQuote`, 0)
        //await this.setQuoteImagesOrderIndex()
        this.$emit('update-image')
      }
      this.selectedImageId = ''
    }
  }
}
</script>

<style lang="scss" scoped>
// .title {
//   border-bottom: solid 1px rgb(196, 196, 196);
// }
.card .card-header .columns {
  justify-content: space-between;
  flex-grow: 1;
}
.category-name {
  line-height: 1.8;
}

.is-multiline.dragable-box {
  min-height: 3em;
  border: dashed 2px #e3e2e2;
  border-radius: 2px;
  margin-left: 2px;
  margin-right: 3px;
  background-color: #fafafa;
}

.is-multiline.dragable-box .column {
  max-width: fit-content;
}
</style>